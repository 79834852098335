import LazyLoad from "vanilla-lazyload";
import Shuffle from "shufflejs";

if (document.getElementById('readMoreBtn')) {
  document.getElementById('readMoreBtn').addEventListener('click', (e) => {
    e.preventDefault();
    document.getElementById('blog-content').scrollIntoView({ behavior: "smooth", block: "start" })
  });
}

[...document.querySelectorAll('ul.sort-options li')].forEach(el => el.addEventListener('click', (e) => { _handleSortChange(e); }));
function sortByDate(element) { return element.getAttribute('data-date-created'); }
function sortByTitle(element) { return element.getAttribute('data-title').toLowerCase(); }
function _handleSortChange (evt) {
  var value = evt.target.getAttribute('value');
  var no_underline = evt.target.parentElement.children;
  var i;
  for ( i = 0; i < no_underline.length; i++ ) { no_underline[i].style.textDecoration = 'none'; }
  evt.target.style.textDecoration = 'underline';
  var options;
  if (value === 'date-created') {
    options = {
      reverse: true,
      by: sortByDate,
    };
  } else if (value === 'title') {
    options = { by: sortByTitle, };
  } else {
    options = {};
  }
  window.shuffle.sort(options);
};

window.shuffle = new Shuffle(document.querySelector(".blogIndex-articles"), {
  itemSelector: '.blogIndex-post__article',
  isCentered: document.querySelectorAll('.blogIndex-post__article').length > 2 ? false : true,
  gutterWidth: 20,
  useTransforms: true,
  initialSort: {
    reverse: true,
    by: sortByDate,
  }
});

var lazyLoadInstances = [];

var initOneLazyLoad = function (horizContainerElement) {
  var oneLL = new LazyLoad({
    container: horizContainerElement
  });
  lazyLoadInstances.push(oneLL);
  window.shuffle.layout();
};

var lazyLazy = new LazyLoad({
  elements_selector: ".blogIndex-post__image",
  callback_enter: initOneLazyLoad,
  unobserve_entered: true
});